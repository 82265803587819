import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3ea4c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = { id: "introjs-8" }
const _hoisted_4 = { class: "menu-item" }
const _hoisted_5 = { class: "menu-content pt-8 pb-2" }
const _hoisted_6 = { class: "menu-section back mx-3" }
const _hoisted_7 = ["href", "onClick"]
const _hoisted_8 = { class: "ml-4 menu-title" }
const _hoisted_9 = { class: "fw-bold inter-text" }
const _hoisted_10 = ["href", "onClick"]
const _hoisted_11 = { class: "ml-4 menu-title" }
const _hoisted_12 = { class: "fw-bold inter-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, { to: '/assets/list' }, {
              default: _withCtx(() => [
                _createVNode(_component_inline_svg, { src: _ctx.iconArrow }, null, 8, ["src"]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('dashboardMapPage.button.backToAssetList')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rStatus, (status, sKey) => {
          return (_openBlock(), _createElementBlock("div", { key: sKey }, [
            (_ctx.detectId !== undefined)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: `/assets/${_ctx.switchHref.includes('/assets/update') ? 'update' : 'views'}/${_ctx.detectId}/${status.url}${status.hash}`
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["menu-item my-4", [
                isActive && 'bg-menu-active',
                isExactActive && 'bg-menu-active',
              ]])
                    }, [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link fs-5"]),
                        href: href,
                        onClick: ($event: any) => (navigate, (_ctx.dataMenuAside = href))
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["number-list-menu", [
                      isActive && 'number-menu',
                      isExactActive && 'number-menu',
                    ]])
                          }, _toDisplayString(sKey + 1), 3),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`dashboardMapPage.button.${status.name}`)), 1)
                        ])
                      ], 10, _hoisted_7)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: `/assets/create/${status.url}${status.hash}`
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["menu-item my-4", [
                isActive && 'bg-menu-active',
                isExactActive && 'bg-menu-active',
              ]])
                    }, [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link fs-5"]),
                        href: href,
                        onClick: ($event: any) => (navigate, (_ctx.dataMenuAside = href))
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["number-list-menu", [
                      isActive && 'number-menu',
                      isExactActive && 'number-menu',
                    ]])
                          }, _toDisplayString(sKey + 1), 3),
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(`dashboardMapPage.button.${status.name}`)), 1)
                        ])
                      ], 10, _hoisted_10)
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ]))
        }), 128))
      ])
    ])
  ], 512))
}

import { defineComponent, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from 'vue-router';
import images from '@/assets/ts/images.js';

export default defineComponent({
  name: 'components-pages-menu-admin',
  components: {},
  setup() {
    const { t, te } = useI18n();
    const iconArrow = images.doubleArrow;
    const route = useRoute();
    const router = useRouter();
    const rStatus = ref([
      {
        name: 'adminHistory',
        url: 'history',
        hash: '#admin-history'
      },
      {
        name: 'historyActivity',
        url: 'history-activity',
        hash: '#history-activity'
      },
      {
        name: 'monitoringCoordinate',
        url: 'coordinate',
        hash: '#admin-monitor-coordinate'
      }
    ]);
    const dataMenuAside = ref('');
    const detectId = ref('') as any

    const switchHref = computed(() => {
      return route.path;
    });

    onMounted(() => {
      const route = router.currentRoute.value.params.id
      detectId.value = route
    });

    return {
      rStatus,
      dataMenuAside,
      switchHref,
      iconArrow,
      detectId,
    };
  },
});

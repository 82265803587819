
import { computed, ref } from 'vue';
import { ElDialog, ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { capitalize } from '@/core/helpers/helps';
import { Actions } from '@/store/enums/StoreEnums';

  export default {
    name: "DownloadAssetModal",
    components :{
        ElDialog,
     },
    props: {
      open: {
        required: true,
        default: false,
        type: Boolean,
      },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const searchParams = ref('');
        const totalAsset = ref({
          status : false,
          jumlah : 0
        })
        const local_region = ref('')
        const loadingDownload = ref(false)
        const loadingCount = ref(false)
        const requiredError = ref(false)
        const value = ref('')
        const channels_id = computed(() => {
            return store.getters.getIdChannel;
        });

        /**
         * close modal emmit
         */
        const close = () => {
            emit('actionShow')
        };

        const downloadAsset = () => {
            requiredError.value = false
            if (local_region.value === '') {
                requiredError.value = true
                return;
            }

            loadingDownload.value = true
            /**
             * build payload
             */
            let tmpChannel = channels_id.value.toString()
            let payload:any = {
                local_region : local_region.value,
                channel_id : channels_id.value.length < 1 ? [] : tmpChannel.split(',')
            }
            
            store
              .dispatch(Actions.DOWNLOAD_OUTSIDE_INSIDE_ASSET, payload)
              .then((res) => {
                loadingDownload.value = false
                const a = document.createElement("a");
                let link = '';
                /**
                 * jika response berupa link File
                 */
                if (res?.data) {
                    /**
                     * Jika Berupa Array
                     */
                    if (Array.isArray(res?.data)) {
                        ElNotification({
                            title: 'Informasi',
                            message: 'Format Data Tidak Sesuai',
                            type: 'warning',
                        })
                        
                        return;
                    }
                    /**
                     * jika Berupa Link
                     */
                    if (typeof res?.data === 'string') {
                        let checkLink = res?.data;
                        let splitLink = checkLink.split('://')

                        /**
                         * paksa menjadi Https
                         */
                        window.open(`https://${splitLink[1]}`, '_blank')

                        return;
                    }
                    /**
                     * jika file data berupa Blob
                     */
                    const blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    link = URL.createObjectURL(blob);
                    
                    /**
                     * process download
                     * init filename download
                     */
                    a.download = `data_asset_${local_region.value}_${new Date(Date.now()).toISOString()}.xlsx`;
                    a.href = link;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    return;
                }

                ElNotification({
                    title: 'Maaf',
                    message: 'Data Download Tidak Ditemukan',
                    type: 'warning',
                });
              })
              .catch((err) => {
                ElNotification({
                    title: 'Maaf',
                    message: 'Gagal mendownload data',
                    type: 'error',
                })

                loadingDownload.value = false
              });
        }

        const locationRegionSelect = (select:any) => {
            loadingCount.value = true
            requiredError.value = false
            /**
             * build payload
             */
             let tmpChannel = channels_id.value.toString()
            let payload:any = {
                local_region : local_region.value,
                channel_id : channels_id.value.length < 1 ? [] : tmpChannel.split(',')
            }
            store
              .dispatch(Actions.DOWNLOAD_OUTSIDE_INSIDE_ASSET_COUNT, payload)
              .then((res) => {
                loadingCount.value = false
                if (res.success) {
                  totalAsset.value = {
                    status : true,
                    jumlah : res.data.total
                  }

                  return;
                }

                totalAsset.value = {
                    status : false,
                    jumlah : 0
                  }
              }).catch(() => {
                loadingCount.value = false
              })
        }

        const options = [
            {
                value: 'ALL',
                label: 'pageListAssets.modalDownloadAsset.option.ALL',
            },
            {
                value: 'OUTSIDE',
                label: 'pageListAssets.modalDownloadAsset.option.OUTSIDE',
            },
            {
                value: 'INSIDE',
                label: 'pageListAssets.modalDownloadAsset.option.INSIDE',
            },
        ]

        const initModal = () => {
          searchParams.value = ''
          totalAsset.value = {
            status : false,
            jumlah : 0
          }
          local_region.value = ''
          value.value = ''
        }
        

      return {
        close,
        t,
        capitalize,
        searchParams,
        options,
        value,
        downloadAsset,
        channels_id,
        local_region,
        locationRegionSelect,
        loadingDownload,
        requiredError,
        totalAsset,
        loadingCount,
        initModal
      };
    },
  };
  
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f6f34de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "title-import" }
const _hoisted_5 = { class: "subtitle-import" }
const _hoisted_6 = {
  class: "row justify-content-center d-flex",
  style: {"height":"78px","margin-top":"-24px"}
}
const _hoisted_7 = { style: {"color":"#B31E72"} }
const _hoisted_8 = { style: {"color":"#B31E72"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event)),
      "close-on-click-modal": true,
      "close-on-press-escape": false,
      "show-close": false,
      "destroy-on-close": "",
      width: "30%"
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('pageListAssets.modalImportAsset.selection.title')), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('pageListAssets.modalImportAsset.selection.detail')), 1)
            ])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogAssets('new'))),
            class: "col d-flex w-100 mx-3 border-selection"
          }, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('pageListAssets.modalImportAsset.selection.newAsset')), 1)
          ]),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogAssets('update'))),
            class: "col d-flex w-100 mx-3 border-selection"
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('pageListAssets.modalImportAsset.selection.updateAsset')), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
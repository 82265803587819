
  import { computed, defineComponent, reactive, ref } from "vue";
  import { useStore } from "vuex";
  import { Actions, Mutations } from "@/store/enums/StoreEnums";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "component-add-new-menu-modal",
    setup() {
      const store = useStore();
      const { t } = useI18n();
      const errorImport = ref(false) as any;
  
      const modal = computed({
        get: () => {
          return store.getters.statusComponent;
        },
        set: (value) => store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, value),
      });
  
      const dialogAssets = (type) => {
        store.commit(Mutations.SET_IMPORT_TYPE, type)
        // setTimeout(() => {
        //     store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, false);
        // }, 500);
        setTimeout(() => {
            store.commit(Mutations.SET_DIALOG_MODAL_IMPORT, true)
        }, 500);
      }
  
      return {
        errorImport,
        modal,
        store,
        dialogAssets,
        t
      };
    },
  });
  
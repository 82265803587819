
import { defineComponent, onMounted, onUpdated, computed } from "vue";
import { ToggleComponent } from "../../assets/ts/components/_ToggleComponent";
import MenuAssets from "@/components/pages/assets/MenuAssets.vue";
import MenuAdmin from "@/components/pages/assets/MenuAdmin.vue";
import { asideTheme } from "../../core/helpers/config";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "layout-aside-project-list",
  components: {
    MenuAssets,
    MenuAdmin
  }, 
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const route = useRoute();
    const detectPath = computed(() => {
      return route.path;
    });
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      detectPath
    };
  },
});

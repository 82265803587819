
import {
  defineComponent,
  watch,
  computed,
  watchEffect,
  onMounted,
  ref,
  unref,
toRaw
} from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useRouter, useRoute } from "vue-router";
import images from "@/assets/ts/images.js";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Search } from "@element-plus/icons-vue";
import SelectionImport from "@/components/modals/import-assets/selectionImport.vue";
import DownloadAssetModal from "@/components/modals/list-asset/DownloadAssetModal.vue";
import { useI18n } from "vue-i18n";
import { checkPermission, checkPermissionUpdate } from "@/core/helpers/helps";
import { ActionsAdminValidasi, MutationsAdminValidasi } from "@/store/enums/StoreAdminValidasiEnums";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "toolbar-project",
  components: {
    DownloadAssetModal,
    SelectionImport
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    
    const iconEdit = images.edit;
    const iconMap = images.map;
    const iconDownload = images.download;
    const route = useRoute();
    const searchValue = ref("");
    const isDownloadOpen = ref(false)
    const getUpdateAssetFlag = computed(() => {
      return store.getters.getUpdateAssetFlag;
    });
    const switchRequest = computed(() => {
      return store.getters.getSwitch;
    });
    const ApprovalList = ref(computed(() => store.getters.ApprovalList));
    const ValidasiList = ref(computed(() => store.getters.ValidasiList));
    const error = ref(computed(() => store.getters.getErrors));

    const dialogImportAssets = computed(() => {
      return store.getters.statusComponent;
    });

    const buttonRef = ref()
    const popoverRef = ref()
    const onClickOutside = () => {
      unref(popoverRef).popperRef?.delayHide?.()
    }

    const reloadPage = () => {
      window.location.reload();
    };

    const openModal = () => {
      store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, true);
    };

    const openModalRequestReview = (status) => {
      store.commit(MutationsAdminValidasi.SET_BULK_REQUESTREVIEW_MODAL, status)
    }

    const loadingDownload = ref(false);

    const openModalDownload = () => {
      isDownloadOpen.value = !isDownloadOpen.value
    }

    const downloadAssets = () => {
      openModalDownload()
    };

    const createNew = () => {
      router.push({ path: "/assets/create/informasi-assets#FSD" });
    };

    const detectPath = computed(() => {
      return route.path;
    });

    const BackToListAsset = () => {
      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        validated: false,
        request_review: false,
        verified: false,
      })
      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: false,
        validated: false,
        request_review: false,
        verified: false,
      });
    };

    watch(error, (currentState, prevCurrentState) => {
      currentState?.show &&
        ElNotification({
          title: currentState?.status || "ERROR",
          message: currentState,
          type: "error",
        });
    });

    const getDetailAsset = computed(() => {
      return store.getters.getDetailAsset;
    });

    const ListRequestUpdate = () => {
      // if (!switchRequest.value) {
        store.commit(Mutations.SET_UPDATED_ASSET, false)
        store.commit(Mutations.SET_LOADING, true);
        let payload = [] as any
        if (store.state.AuthModule.userProfile.role.code === 'admin-validasi') {
          payload = '631ed2f9f97fb56ec2fcc0e8'
        } else if (store.state.AuthModule.userProfile.role.code === 'admin-verifikasi') {
          payload ='631ed318f97fb56ec2fcc0ea'
        } else {
          payload = ['631ed2f9f97fb56ec2fcc0e8', '631ed318f97fb56ec2fcc0ea'].toString()
        }
        store.dispatch(ActionsAdminValidasi.GET_REQUEST_EDIT_HISTORY, {
          user_id: store.state.AuthModule.userProfile._id,
          code: store.state.AuthModule.userProfile.role.code,
          Id: payload
        })
        .then((res) => {
          store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
            status: !store.getters.ApprovalList.status,
            request_review: false,
            validated: checkPermissionUpdate(
              "assetapproval",
              "assetapproval.validate"
            ),
            verified: checkPermissionUpdate(
              "assetapproval",
              "assetapproval.verify"
            ),
          })
          .then((res) => {
            store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
              status: false,
              request_review: false,
              validated: false,
              verified: false,
            })
            .then((suc) => {
              store.commit(MutationsAdminValidasi.SET_SWITCH, true)
              store.commit(Mutations.SET_UPDATE_ASSETS_FLAG, false);
              store.commit(Mutations.SET_LOADING, false);
            })
          })
        })
      // } else {
      //   location.reload()
      //   // store.commit(MutationsAdminValidasi.SET_SWITCH, false)
      //   // BackToListAsset()
      // }
    };

    const requestHistoryUpdate = () => {
      let payload = [] as any
      if (store.state.AuthModule.userProfile.role.code === 'admin-validasi') {
        payload = '631ed2f9f97fb56ec2fcc0e8'
      } else if (store.state.AuthModule.userProfile.role.code === 'admin-verifikasi') {
        payload ='631ed318f97fb56ec2fcc0ea'
      } else {
        payload = ['631ed2f9f97fb56ec2fcc0e8', '631ed318f97fb56ec2fcc0ea'].toString()
      }
      store.dispatch(ActionsAdminValidasi.GET_REQUEST_EDIT_HISTORY, {
        user_id: store.state.AuthModule.userProfile._id,
        code: store.state.AuthModule.userProfile.role.code,
        Id: payload
      });
      
    };

    const updateAssets = () => {
      store.commit(Mutations.SET_UPDATED_ASSET, true)
      store.commit(MutationsAdminValidasi.SET_SWITCH, false)
      BackToListAsset()
      let params = {
        page: 1,
        limit: 10,
        asset_type_codes: "",
        id_channels: "",
        id_provinces: "",
        legalitas_codes: "",
        approval_status:
          "631ed33bf97fb56ec2fcc0ec,631ed34af97fb56ec2fcc0ee,6333b254c352da91999f17bb",
      };
      store.dispatch(Actions.GET_LIST_ASSETS, params)
    };

    const ListValidasi = () => {
      store.commit(Mutations.SET_UPDATED_ASSET, false)
      store.commit(MutationsAdminValidasi.SET_SWITCH, false)
      // BackToListAsset()
      // if (ValidasiList.value.validated) {

      //   return;
      // }

      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: true,
        validated: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.validate"
        ),
        verified: false,
        request_review: false
      })

      store.commit(MutationsAdminValidasi.SET_SWITCH, false)

      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        validated: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.validate"
        ),
        verified: false,
        request_review: false
      })
        
    };

    const ListRequestReview = () => {
      if (ValidasiList.value.request_review) {
        store.commit(MutationsAdminValidasi.SET_SWITCH, false)
        BackToListAsset()

        return;
      }

      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: true,
        request_review: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.request_review"
        ),
        validated:false,
        verified: false,
      })

      store.commit(MutationsAdminValidasi.SET_SWITCH, false)

      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        request_review: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.request_review"
        ),
        verified: false,
        validated:false
      })
        
    };

    const ListVerifikasi = () => {
      store.commit(Mutations.SET_UPDATED_ASSET, false)
      store.commit(MutationsAdminValidasi.SET_SWITCH, false)
      // BackToListAsset()
      // if (ValidasiList.value.verified) {

      //   return;
      // }
      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: true,
        verified: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.verify"
        ),
        validated: false,
        request_review: false
      })

      store.commit(MutationsAdminValidasi.SET_SWITCH, false)
      
      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        verified: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.verify"
          ),
          validated: false,
          request_review: false
      })

    };

    const totalWatingValidation = ref(0)
    const totalWatingVerification = ref(0)
    const totalWatingUpdate = ref(0)

    const getWv = () => {
      store.dispatch(Actions.GET_TOTAL_APPROVAL, '631ed2f9f97fb56ec2fcc0e8')
      .then((data) => {
        totalWatingValidation.value = data.meta.totalRecord
      })
    }

    const getWver = () => {
      store.dispatch(Actions.GET_TOTAL_APPROVAL, '631ed318f97fb56ec2fcc0ea')
        .then((data) => {
          totalWatingVerification.value = data.meta.totalRecord
        })
    }
    
    const getWup = () => {
      store.dispatch(Actions.GET_TOTAL_APPROVAL, '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea,631ed357f97fb56ec2fcc0f0')
        .then((data) => {
          totalWatingUpdate.value = data.meta.totalRecord
        })
    }

    onMounted(() => {
      getWv()
      
      setTimeout(() => {
        getWver()
      }, 500);

      setTimeout(() => {
        getWup()
      }, 1000);
      
      store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, false);
      store.commit(Mutations.SET_ALL_ASSET, true)
    });

    /**
     *
     * @param el text yang menjadi parameter pencarian
     */
    const timer = ref(0) as any
    const handleSearch = (el) => {
      // Delaying saat melakukan pencarian
      if (timer.value) {
          clearTimeout(timer.value);
          timer.value = null;
      }
      timer.value = setTimeout(() => {
        store.dispatch(Actions.CREATE_SEARCH_ASSETS, {
          search: el.target.value,
        });
        activeName.value = 'all'
      }, 2000);
    };

    const clearSearch = () => {
      store.dispatch(Actions.CREATE_SEARCH_ASSETS, {
        search: "",
      });
    };

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });

    const PayloadAction = computed(() => {
      return store.getters.PayloadAction
    })

    const confirmEvent = () => {
      store.commit(Mutations.SET_DELETE_MODAL, true)
    }

    const toPlayground = () => {
      window.open(`${window.location.origin}/playground-coordinate`, '_blank')
    }

    const activeName = ref('all')

    const assetIdentify = (id) => {
      store.commit(Mutations.SET_UPDATED_ASSET, false)
      if (ValidasiList.value.request_review) {
        store.commit(MutationsAdminValidasi.SET_SWITCH, false)
        BackToListAsset()

        return;
      }
      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        request_review: true,
        validated: false,
        verified: false
      })
      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: false,
        request_review: checkPermissionUpdate(
          "assetapproval",
          "assetapproval.request_review"
        ),
        validated:false,
        verified: false,
      })
      .then((suc) => {
        store.commit(MutationsAdminValidasi.SET_SWITCH, false)
        store.commit(Mutations.SET_ASSET_INITIAL, id)
        store.commit(Mutations.SET_UPDATE_ASSETS_FLAG, false);
        store.commit(Mutations.SET_RESET_FILTER, false);
      })
    }

    const resetfilter = () => {
      store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
        status: false,
        validated: false,
        request_review: false,
        verified: false,
      })
      store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
        status: false,
        validated: false,
        request_review: false,
        verified: false,
      })
      .then((suc) => {
        store.commit(Mutations.SET_UPDATED_ASSET, false)
        store.commit(MutationsAdminValidasi.SET_SWITCH, false)
        store.commit(Mutations.SET_ASSET_INITIAL, '')
        store.commit(Mutations.SET_UPDATE_ASSETS_FLAG, false);
        store.commit(Mutations.SET_RESET_FILTER, true);
        let params = {
          page: 1,
          limit: 10,
          asset_type_codes: "",
          id_channels: "",
          id_provinces: "",
          legalitas_codes: "",
          approval_status:""
        };
        store.dispatch(Actions.GET_LIST_ASSETS, params)
      })
    }

    const handleClick = (event: Event) => {
      switch (Object(toRaw(event)).props.name) {
        case 'all':
          store.commit(Mutations.SET_SHOW_TOOLBAR, true)
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          asset_initial.value = false
          store.commit(Mutations.SET_ALL_ASSET, true)
          resetfilter()
          break
        case 'initial':
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          asset_initial.value = true
          store.commit(Mutations.SET_ALL_ASSET, false)
          assetIdentify('6333b254c352da91999f17bb')
          break
        case 'waiting_updated':
          store.commit(Mutations.SET_SHOW_TOOLBAR, true)
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          store.commit(Mutations.SET_ALL_ASSET, false)
          asset_initial.value = false
          ListRequestUpdate()
          break
        case 'waiting_validation':
          store.commit(Mutations.SET_SHOW_TOOLBAR, true)
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          store.commit(Mutations.SET_ALL_ASSET, false)
          asset_initial.value = false
          ListValidasi()
          break
        case 'waiting_verification':
          store.commit(Mutations.SET_SHOW_TOOLBAR, true)
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          store.commit(Mutations.SET_ALL_ASSET, false)
          asset_initial.value = false
          ListVerifikasi()
          break
        default:
          store.commit(Mutations.SET_SHOW_TOOLBAR, true)
          store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...store.getters.PayloadAction,
            entity_id : ''
          })
          store.commit(Mutations.SET_ALL_ASSET, false)
          asset_initial.value = false
          updateAssets()
          break
      }
    }

    const loading = ref(computed(() => store.getters.Loading));
    
    const allAsset = ref(computed(() => store.getters.assetsAll));
    
    const getshowToolbar = ref(computed(() => store.getters.getshowToolbar));

    const asset_initial = ref(false)

    return {
      t,
      router,
      route,
      toolbarWidthFluid,
      createNew,
      getProfile,
      switchRequest,
      getDetailAsset,
      iconEdit,
      iconDownload,
      detectPath,
      iconMap,
      dialogImportAssets,
      openModal,
      handleSearch,
      searchValue,
      Search,
      clearSearch,
      checkPermission,
      ListRequestUpdate,
      ListValidasi,
      ApprovalList,
      ValidasiList,
      downloadAssets,
      BackToListAsset,
      checkPermissionUpdate,
      ListVerifikasi,
      requestHistoryUpdate,
      updateAssets,
      getUpdateAssetFlag,
      reloadPage,
      loadingDownload,
      buttonRef,
      popoverRef,
      onClickOutside,
      isDownloadOpen,
      openModalDownload,
      ListRequestReview,
      PayloadAction,
      confirmEvent,
      toPlayground,
      openModalRequestReview,
      activeName,
      handleClick,
      loading,
      asset_initial,
      allAsset,
      getshowToolbar,
      totalWatingValidation,
      totalWatingVerification,
      totalWatingUpdate
    };
  },
});

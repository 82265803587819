import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_AsideMenuDrawer = _resolveComponent("AsideMenuDrawer")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_KTToolbarOut = _resolveComponent("KTToolbarOut")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.loaderEnabled)
        ? (_openBlock(), _createBlock(_component_KTLoader, {
            key: 0,
            logo: _ctx.loaderLogo
          }, null, 8, ["logo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AsideMenuDrawer, {
          lightLogo: _ctx.themeLightLogo,
          darkLogo: _ctx.themeDarkLogo
        }, null, 8, ["lightLogo", "darkLogo"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
          _createElementVNode("div", {
            id: "kt_content",
            class: "content d-flex flex-column flex-column-fluid",
            style: _normalizeStyle(_ctx.pageOpen !== 'informasi_assets' ? 'margin-top: 30px' : '')
          }, [
            _createVNode(_component_KTToolbar),
            (_ctx.breadCrumbBar)
              ? (_openBlock(), _createBlock(_component_KTToolbarOut, {
                  key: 0,
                  class: "my-3",
                  breadcrumbs: _ctx.breadcrumbs,
                  title: _ctx.pageTitle
                }, null, 8, ["breadcrumbs", "title"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                id: "kt_content_container",
                class: _normalizeClass({
                'container-fluid': _ctx.contentWidthFluid,
                'container-fluid': !_ctx.contentWidthFluid,
              })
              }, [
                _createVNode(_component_router_view)
              ], 2)
            ])
          ], 4),
          _createVNode(_component_KTFooter)
        ])
      ])
    ]),
    _createVNode(_component_KTScrollTop)
  ], 64))
}

import { computed, defineComponent, PropType } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: {
      type: Array as PropType<Array<any>>,
      default: () => []
    },
    title: String,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const pageOpen = computed(() => {
      return route.name;
    });
    const breadcrumbKey = computed(() => store.getters.getBreadcrumbKey)
    const pageTitle = computed(() => store.getters.pageTitle)

    const handleBreadcrumbClick = (path, index) => {
      if (index + 1 < props.breadcrumbs.length)
        router.push({ name: path.toLowerCase() })
      else return
    }

    const data = JSON.parse(window.localStorage.getItem("role") as any)
    const activeItems = data?.page_permissions

    return {
      handleBreadcrumbClick,
      pageOpen,
      toolbarWidthFluid,
      breadcrumbKey,
      pageTitle,
      activeItems,
      route
    };
  },
});
